import React from "react";
import { graphql } from "gatsby";
import Tags from "../components/tags";

export const query = graphql`
  query {
    allPost(sort: { fields: tags___name, order: DESC }) {
      group(field: tags___name) {
        fieldValue
        totalCount
      }
    }
  }
`;

type Props = {
  data: {
    allPost: {
      group: {
        fieldValue: string;
        totalCount: number;
      }[];
    };
  };
};

export default function MinimalBlogCoreTags({ data }: Props) {
  const { allPost } = data;

  return <Tags list={allPost.group} />;
}
